import { Box3, BufferGeometry, Object3D, Vector3 } from "three";

const boundingBox = new Box3();
const center = new Vector3();

export const getObjectOffset = (object3D: Object3D): Vector3 => {
  boundingBox.setFromObject(object3D);
  boundingBox.getCenter(center);

  const offset = new Vector3().copy(center);

  return offset;
};

export const getGeometryOffset = (bufferGeometry: BufferGeometry): Vector3 => {
  if (!bufferGeometry.boundingBox) bufferGeometry.computeBoundingBox();

  boundingBox.copy(bufferGeometry.boundingBox!);
  boundingBox.getCenter(center);

  const offset = new Vector3().copy(center);

  return offset;
};
