import { createStore } from "./createStore";

export type Controls = {
  position: boolean;
  rotation: boolean;
};

export type TransformStore = {
  controls: Controls;
  setControls: (controls: Partial<Controls>) => void;
};

export const useTransformStore = createStore<TransformStore>("TransformStore", set => ({
  controls: { position: true, rotation: false },
  setControls: controls =>
    set(state => ({
      controls: {
        ...state.controls,
        ...controls,
      },
    })),
}));
