import { FC } from "react";

import { MainScene } from "../";
import { useGroupsStore, GroupsStore } from "../../store";
import { Renderer } from "./components";
import DataDisplay from "./components/DataDisplay";

const groupsSelector = (state: GroupsStore) => state.entityGroups;
const xmlDataSelector = (state: GroupsStore) => state.xmlData;

type Props = {
  currentGroupIndex: number;
};

const GroupEntityRenderer: FC<Props> = ({ currentGroupIndex }) => {
  const entityGroups = useGroupsStore(groupsSelector);
  const xmlData = useGroupsStore(xmlDataSelector);

  return (
    <MainScene>
      {Object.keys(entityGroups).map((key, index) => {
        const numericKey = parseInt(key);
        if (isNaN(numericKey)) {
          return null;
        }

        const group = entityGroups[numericKey];
        const isVisible = currentGroupIndex === index;

        return group.map((g, gIndex) => {
          return (
            <Renderer
              key={`${index}-${gIndex}`}
              rendererType={g.type}
              url={g.url}
              name={g.name}
              isVisible={isVisible}
              componentType={g.componentType}
              groupType={g.groupType}
            />
          );
        });
      })}
      <DataDisplay xmlData={xmlData} currentGroupIndex={currentGroupIndex} />
    </MainScene>
  );
};

export default GroupEntityRenderer;
