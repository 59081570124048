import { FC } from "react";

import { useTranslation } from "react-i18next";

import { useEntitiesStore } from "../../../store";

const TransformResetControls: FC = () => {
  const { t } = useTranslation();
  const setReset = useEntitiesStore(state => state.setReset);

  return (
    <div className="container">
      {t("panels:transformReset")}
      <div className="buttonGroup">
        <button className="active" onPointerDown={() => setReset(true)}>
          {t("panels:reset")}
        </button>
      </div>
    </div>
  );
};

export default TransformResetControls;
