import { FC, useEffect, useMemo } from "react";

import { TransformControls as Controls } from "@react-three/drei";
import { useThree } from "@react-three/fiber";

import { useEntitiesStore, useTransformStore } from "../../../store";

const TransformControls: FC = () => {
  const scene = useThree(state => state.scene);
  const { selected, reset, setReset } = useEntitiesStore();
  const { position, rotation } = useTransformStore(state => state.controls);

  const selectedObject = useMemo(() => {
    if (!selected) {
      return null;
    }

    return scene.getObjectByName(selected);
  }, [selected]);

  useEffect(() => {
    if (!reset) return;

    selectedObject?.position.set(0, 0, 0);
    selectedObject?.quaternion.set(0, 0, 0, 0);

    setReset(false);
  }, [reset]);

  if (!selectedObject) return null;

  return (
    <group>
      <Controls object={selectedObject} visible={position} enabled={position} mode="translate" />
      <Controls object={selectedObject} visible={rotation} enabled={rotation} mode="rotate" />
    </group>
  );
};

export default TransformControls;
