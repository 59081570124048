import { FC } from "react";

import { useTranslation } from "react-i18next";

import { useTransformStore } from "../../../store";

const TransformControls: FC = () => {
  const { t } = useTranslation();
  const { controls, setControls } = useTransformStore();

  return (
    <div className="container">
      {t("panels:transformControls")}
      <div className="buttonGroup">
        <button
          className={controls.position ? "active" : "inactive"}
          onPointerDown={() => setControls({ position: !controls.position, rotation: controls.position })}
        >
          {t("panels:position")}
        </button>
        <button
          className={controls.rotation ? "active" : "inactive"}
          onPointerDown={() => setControls({ position: controls.rotation, rotation: !controls.rotation })}
        >
          {t("panels:rotation")}
        </button>
      </div>
    </div>
  );
};

export default TransformControls;
