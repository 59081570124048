import { FC, useEffect, useRef } from "react";

import { useLoader, ThreeEvent } from "@react-three/fiber";
import { Color, Group, Mesh, Vector3 } from "three";
import { PLYLoader } from "three/examples/jsm/loaders/PLYLoader";

import { MATERIAL_CONFIG, GROUP_ROTATION } from "../../../constants";
import { useHandleBoundingBox } from "../../../hooks";
import { VisibilityComponentProps } from "../../../types";
import { getGeometryOffset } from "../../../utils";

const PLYRenderer: FC<VisibilityComponentProps> = ({
  url,
  name,
  isVisible,
  componentType,
  groupType,
  color,
  opacity = 1,
  setSelected,
}) => {
  const groupRef = useRef<Group>();
  const innerRef = useRef<Group>();
  const meshRef = useRef<Mesh>();

  const object = useLoader(PLYLoader, url);

  useHandleBoundingBox(groupRef, isVisible);

  useEffect(() => {
    object.computeVertexNormals();

    const centerPosition = getGeometryOffset(object);
    const centerOffet = new Vector3().copy(centerPosition).multiplyScalar(-1);

    innerRef.current?.position.copy(centerPosition);
    meshRef.current?.position.copy(centerOffet);
  }, [object]);

  const handleFileSelection = (e: ThreeEvent<MouseEvent>): void => {
    e.stopPropagation();
    setSelected && setSelected(name);
  };

  /**  isVisible hides models that are not related to current Stepper view in Group view */
  if (!isVisible) {
    return null;
  }

  return (
    <group
      ref={groupRef}
      quaternion={GROUP_ROTATION}
      onPointerDown={handleFileSelection}
      userData={{ componentType, groupType }}
    >
      <group ref={innerRef}>
        <group name={name}>
          <mesh ref={meshRef} geometry={object}>
            <meshPhongMaterial
              side={2}
              refractionRatio={0}
              reflectivity={0}
              fog={false}
              flatShading={false}
              shininess={MATERIAL_CONFIG.SHININESS}
              specular={new Color(MATERIAL_CONFIG.SPECULAR_COLOR)}
              color={new Color(color ? color : MATERIAL_CONFIG.COLOR)}
              transparent={opacity !== 1}
              depthWrite={opacity === 1}
              opacity={opacity}
            />
          </mesh>
        </group>
      </group>
    </group>
  );
};

export default PLYRenderer;
