import { FC, useState } from "react";

import { useTranslation } from "react-i18next";

import CameraViewControls from "./components/CameraViewControls";
import DisplayControls from "./components/DisplayControls";
import TransformControls from "./components/TransformControls";
import TransformResetControls from "./components/TransformResetControl";

import "./ControlPanel.scss";

const ControlPanel: FC = () => {
  const { t } = useTranslation();
  const [visible, setVisible] = useState(false);

  return (
    <div className="controlPanel">
      <button className="switch" onPointerDown={() => setVisible(prev => !prev)}>
        {t("panels:controlPanel")}
      </button>
      {visible && (
        <div className="controls">
          <CameraViewControls />
          <DisplayControls />
          <TransformControls />
          <TransformResetControls />
        </div>
      )}
    </div>
  );
};

export default ControlPanel;
